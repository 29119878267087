<template>
  <main>
    <!-- Page not found -->
    <div class="error-page">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="error p-5">
              <img
                src="@/assets/not-found.png"
                class="img-fluid"
                alt="Страница не найдена"
              />
              <h1>Страница не найдена!</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import "./404.scss";

export default {
  name: "ErrorPage",
  metaInfo: {
    title: "Page not found!",
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
